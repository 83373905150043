<fieldset>
  <div class="row">
    <div class="col-sm-4">Name</div>
    <div class="col-sm-7">{{ hospitalOfficerPersonalDetails?.firstName }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4">Surname</div>
    <div class="col-sm-7">{{ hospitalOfficerPersonalDetails?.lastName }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4">Job Title</div>
    <div class="col-sm-7">{{ hospitalOfficerPersonalDetails?.jobTitle }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4">Email</div>
    <div class="col-sm-7">
      <div class="input-field">
        <locumsnest-input-field
          [idx]="idx + '__email'"
          [ngrxFormControlState]="hospitalOfficerForm?.controls?.email"
          [errorMsg]="getErrorMsg(hospitalOfficerForm?.controls?.email)"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">Telephone</div>
    <div class="col-sm-7">
      <div class="input-field">
        <locumsnest-input-field
          [idx]="idx + '__telephone'"
          [ngrxFormControlState]="hospitalOfficerForm?.controls?.telephone"
          [errorMsg]="getErrorMsg(hospitalOfficerForm?.controls?.telephone)"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8">
      <locumsnest-section-title [title]="'Email Preferences'" [titleType]="'h2'" />
    </div>
  </div>
  <ng-container
    *ngTemplateOutlet="
      digestFrequency;
      context: {
        title: 'activity',
        formControl: hospitalOfficerForm?.controls?.activityDigestEmailFrequency
      }
    "
  />
  @if (hasUnlockJobListingRatesPermission) {
    <ng-container
      *ngTemplateOutlet="
        digestFrequency;
        context: {
          title: 'locked shifts',
          formControl: hospitalOfficerForm?.controls?.shiftsLockedDigestEmailFrequency
        }
      "
    />
  }
  @if (hasUnlockTimesheetRatesPermission) {
    <ng-container
      *ngTemplateOutlet="
        digestFrequency;
        context: {
          title: 'locked timesheets',
          formControl: hospitalOfficerForm?.controls?.timeSheetsLockedDigestEmailFrequency
        }
      "
    />
  }

  @if (hasStaffbankManagerPermission) {
    <div class="row">
      <div class="col-sm-4">
        <div class="row">
          <div class="col-sm-12">Receive Staff Bank Rundown Email</div>
        </div>
      </div>
      <div class="col-sm-7 rundown-email">
        <locumsnest-check-box
          theme="blue"
          iconName="filter-tick"
          [idx]="idx + '__receive-staffbank-rundown-email'"
          [ngrxFormControlState]="hospitalOfficerForm?.controls?.receiveStaffbankRundownEmail"
        />
      </div>
    </div>
  }
</fieldset>

<ng-template #digestFrequency let-title="title" let-formControl="formControl">
  <div class="row">
    <div class="col-sm-4">
      <div class="row">
        <div class="col-sm-12">{{ title | titlecase }} Digest Frequency</div>
        <div class="col-sm-12 help-text">
          Configure how often you want to receive {{ title }} digest emails
        </div>
      </div>
    </div>
    <div class="col-sm-7">
      <locumsnest-dropdown-grouping-select
        placeholder="Select {{ title }} digest frequency"
        [idx]="idx + '__' + (title | kebabcase) + '-digest-email-frequency'"
        [options]="emailFrequencies"
        [isDisabled]="!emailFrequencies"
        [ngrxFormControlState]="formControl"
      />
    </div>
  </div>
</ng-template>
